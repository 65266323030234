import React from "react";
import Meta from "./common/Meta";
import ContactForm from "./common/ContactForm";

const ContactUs = () => {
  return (
    <section className="page-contact-us">
      <Meta title={"Contact us | Didomi Company Limited"} />{" "}
      <div className="page-contact-us__header">
        <h2 className="page-contact-us__header-text">CONTACT US</h2>
      </div>
      <div className="page-contact-us__container">
        <div className="page-contact-us__row">
          <div className="page-contact-us__form-info">
            <h3 className="u-margin-bottom-small">Get in touch</h3>
            <p className="paragraph u-margin-bottom-medium">
              Leave your info in the form below and we will get back to you. It
              will be a step in the right direction.
            </p>
            <div className="page-contact-us__form-container">
              <ContactForm />
            </div>
          </div>
          <div className="page-contact-us__location-info">
            <h3 className="u-margin-bottom-small">Location</h3>
            <h4 className="u-margin-bottom-small">Didomi Company Limited</h4>
            <p className="paragraph u-margin-bottom-medium">
              6, Soji Adepegba close Allen Avenue, <br />
              Ikeja Lagos Nigeria. <br />
            </p>
            <h4 className="u-margin-bottom-small">Contact Info</h4>
            <p className="paragraph u-margin-bottom-medium">
              080 7116 7839
              <br />
              091 5637 7772
              <br />
              info@didomiconsortium.com
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
