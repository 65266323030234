import React, { useState, useEffect } from "react";
import { getUserDetails } from "../../services/userService";

const Profile = () => {
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await getUserDetails();
        setUserDetails(data);
        // console.log(data);
      } catch (ex) {
        // console.log(ex);
      }
    };

    getData();
  }, []);

  return (
    <>
      <h3 className="dashboard-main__header">Profile</h3>
      <div className="dashboard-main">
        <div className="dashboard-main__1">
          <div className="user__info-1">
            <h3>Name</h3>
          </div>
          <div className="user__info">
            <div className="user__info-1 user__info-1-dis-none-phone">
              First Name
            </div>
            <div className="user__info-2">{userDetails.firstName}</div>
          </div>
          <div className="user__info">
            <div className="user__info-1 user__info-1-dis-none-phone">
              Last Name
            </div>
            <div className="user__info-2">{userDetails.lastName}</div>
          </div>
          <div className="user__info">
            <div className="user__info-1">
              <h3>Contact Info</h3>
            </div>
          </div>
          <div className="user__info">
            <div className="user__info-1 user__info-1-dis-none-phone">
              Email
            </div>
            <div className="user__info-2">{userDetails.email}</div>
          </div>
          <div className="user__info">
            <div className="user__info-1 user__info-1-dis-none-phone">
              Phone
            </div>
            <div className="user__info-2">{userDetails.phone}</div>
          </div>
          <div className="user__info">
            <div className="user__info-1">
              <h3>Others</h3>
            </div>
          </div>
          <div className="user__info">
            <div className="user__info-1">Bio</div>
            <div className="user__info-2">{userDetails.bio}</div>
          </div>
          <div className="user__info">
            <div className="user__info-1">Country of Study</div>
            <div className="user__info-2">{userDetails.country}</div>
          </div>
          <div className="user__info">
            <div className="user__info-1">Study Level</div>
            <div className="user__info-2">{userDetails.level}</div>
          </div>
          <div className="user__info">
            <div className="user__info-1">Created</div>
            <div className="user__info-2">
              {userDetails.createdAt
                ? userDetails.createdAt.substring(0, 10)
                : null}
            </div>
          </div>
        </div>

        <div className="dashboard-main__2">
          <div className="user__avatar">
            <i className="bi bi-person-circle"></i>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
