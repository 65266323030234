import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { getNewPassword, setNewPassword } from "./../services/userService";
import Meta from "./common/Meta";
import auth from "../services/authService";

const NewPassword = () => {
  const navigate = useNavigate();
  const params = useParams();

  let [loading, setLoading] = useState(false);

  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [userId, setUserId] = useState("");
  const [passwordToken, setPasswordToken] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const { data } = await setNewPassword(userId, passwordToken, password);
      // console.log(data);
      alert(data.message);
      setLoading(false);
      window.location.pathname = "/";
    } catch (ex) {
      setLoading(false);
      setErrorMessage(ex.response.data);
      // console.log(ex);
    }
  };

  useEffect(() => {
    const getPassword = async () => {
      try {
        const { data } = await getNewPassword(params.token);
        setUserId(data.userId);
        setPasswordToken(data.passwordToken);
      } catch (ex) {
        console.log(ex.response.data);
        setErrorMessage(ex.response.data);
      }
    };

    if (auth.getCurrentUser()) navigate("/");
    getPassword();
  }, [params, navigate]);

  return (
    <div className="reset-password">
      <Meta title={"New password | Didomi Company Limited"} />
      <div className="reset-password__form-container">
        <form
          className="form-content"
          onSubmit={(e) => handleSubmit(e)}
          noValidate
        >
          <input
            type="password"
            value={password}
            name="password"
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
          <br />
          <ClipLoader color="#54aa01" loading={loading} />
          <p className="form-content__error-message">{errorMessage}</p>
          <button type="submit">Update Password</button>
        </form>
      </div>
    </div>
  );
};

export default NewPassword;
