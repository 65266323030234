import React, { useState, useEffect, useRef } from "react";

const SectionOne = () => {
  const secRef = useRef();
  const minRef = useRef();

  const [min, setMin] = useState(0);

  let intervalId = 0;
  let count = 1;
  const seconds = 60;

  function playText() {
    secRef.current.innerText = count;
    secRef.current.style.opacity = "1";
    count = count + 1;
    if (count > seconds) {
      count = 1;
      setMin(min + 1);
    }
  }

  intervalId = setInterval(playText, 1000);

  useEffect(() => {
    if (min >= 1) {
      minRef.current.style.visibility = "visible";
      minRef.current.style.opacity = "1";
    } else {
      minRef.current.style.visibility = "hidden";
      minRef.current.style.opacity = "0";
    }
    return () => clearInterval(intervalId);
  }, [intervalId, min]);

  return (
    <>
      <div ref={minRef} className="technology__mins" onClick={() => setMin(0)}>
        {min}min
      </div>
      <div className="technology__header">
        <div className="technology__header-text">
          <h4 className="technology__header-sub">WE ARE DIDOMI TECHNOLOGY</h4>
          <h2 className="technology__header-main heading-secondary-white">
            An IT agency <br />
            focused on Web.
          </h2>
          <p className="technology__header-paragraph">
            Didomi Technology is a Software and Web development Company. We
            provide BEST services from custom software development, web
            design/development, search engine optimisation.{" "}
          </p>
          <p className="technology__header-paragraph u-margin-top-small">
            Our team of developers are ready to look at your project and offer
            you free technical Consultation
          </p>
        </div>
        <div className="technology__header-time">
          <div ref={secRef} className="technology__seconds">
            00
          </div>
          <div className="technology__time">{new Date().toDateString()}</div>
        </div>
      </div>
    </>
  );
};

export default SectionOne;
