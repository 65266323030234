import React, { useState } from "react";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";
import { sendEmail } from "./../../services/mailService";

const SendEmail = () => {
  let [loading, setLoading] = useState(false);

  const [text, setText] = useState("");
  const [subject, setSubject] = useState("");
  const [email, setEmail] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [emailList, setEmailList] = useState([]);

  const schema = {
    email: Joi.string().required().email().label("Email address"),
  };

  const addEmail = (e) => {
    e.preventDefault();

    const { error } = Joi.validate({ email }, schema);

    if (error) {
      // console.log(error.details[0].path[0]);
      setEmailErrorMessage(error.details[0].message);
    } else {
      setEmailErrorMessage("");
      let dupEmailList = [...emailList];
      dupEmailList.push(email);
      setEmailList(dupEmailList);
    }
  };

  const deleteEmail = (index) => {
    let dupEmailList = emailList.filter((item, i) => i !== index);
    setEmailList(dupEmailList);
  };

  const emailSender = async (mail) => {
    try {
      const { data } = await sendEmail(mail);
      toast(`${data.message}`, { className: "toast-style" });
      setEmail("");
      setEmailList([]);
      setSubject("");
      setText("");
      setLoading(false);
    } catch (ex) {
      console.log(ex);
      setErrorMessage(ex.response.data.message);
      setLoading(false);
    }
  };

  const handleSendEmail = (e) => {
    e.preventDefault();

    if (emailList.length === 0) {
      setErrorMessage("No email is added. You must add at least one email.");
      return;
    }

    setLoading(true);
    setErrorMessage("");
    emailSender({
      emailList,
      subject,
      text,
    });
    // console.log("sent");
  };

  return (
    <div className="">
      <h3 className="dashboard-main__header">Send Email to Applicants</h3>
      <div className="dashboard-main">
        <div className="dashboard-main__1">
          <form className="email-form" onSubmit={(e) => addEmail(e)} noValidate>
            <label htmlFor="email">To:</label>
            <input
              type="email"
              value={email}
              name="email"
              id="email"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email address"
            />
            <button className="email-form__add" type="submit">
              <i className="bi bi-plus"></i>
            </button>{" "}
            <p className="form-content__error-message"> {emailErrorMessage} </p>{" "}
            {emailList.map((item, index) => (
              <div key={index} className="email-form__emails">
                <p>{item}</p>
                <button
                  className="email-form__delete"
                  onClick={() => deleteEmail(index)}
                >
                  <i className="bi bi-trash3"></i>
                </button>
              </div>
            ))}
          </form>
          <form
            className="form-content"
            onSubmit={(e) => handleSendEmail(e)}
            noValidate
          >
            <input
              type="text"
              value={subject}
              name="subject"
              onChange={(e) => setSubject(e.target.value)}
              placeholder="Subject"
            />
            <textarea
              rows={7}
              value={text}
              name="text"
              onChange={(e) => setText(e.target.value)}
              placeholder="Text here!"
            />
            <ClipLoader color="#54aa01" loading={loading} />
            <p className="form-content__error-message"> {errorMessage} </p>{" "}
            <button type="submit"> Send </button>{" "}
          </form>{" "}
        </div>
      </div>
    </div>
  );
};

export default SendEmail;
