import http from "./httpService";

const apiEndpoint = "/universities";

export function getUniversities() {
  return http.get(apiEndpoint);
}

export function searchUniversities(keyword) {
  return http.get(`${apiEndpoint}/search?keyword=${keyword}`);
}
