import React from "react";

const Section = () => {
  return (
    <div className="tech-section">
      <h2 className="heading-secondary-white">
        Illuminating your business at every touchpoint.
      </h2>
    </div>
  );
};

export default Section;
