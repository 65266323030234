import React from "react";
import { Link } from "react-router-dom";

const SectionFive = () => {
  return (
    <section className="section-5">
      {/* <div className="section-5__heading-main u-margin-top-large">
        <h2 className="">At Didomi </h2>
      </div> */}
      <div className="section-5__heading-sub u-margin-top-large u-margin-bottom-small">
        <h2 className="heading-secondary">
          We See Health And Safety Differently{" "}
        </h2>
      </div>
      <div className="section-5__content-box">
        <div className="section-5__text">
          <p className="paragraph">
            The purpose of health and safety is to protect you, your workers,
            sub-contractors, customers and members of the public when they are
            involved with your business.
          </p>
          <p className="paragraph u-margin-top-small u-margin-bottom-small">
            Workplace health and safety is important for the health and
            wellbeing of all employees across all industries because human
            injury or loss in any business is not acceptable. And the last thing
            you want is to be the employer who loses an employee in an accident
            and is then charged with corporate manslaughter.
          </p>

          <a as={Link} href="/didomi-safety" className="btn">
            Learn more{" "}
          </a>
        </div>
        <div className="section-5__img-box">
          <img src="img/didomi-27.jpg" alt="didomi-27" />
        </div>
      </div>

      <a href="#top" className="btn u-margin-bottom-small">
        Top{" "}
      </a>
    </section>
  );
};

export default SectionFive;
