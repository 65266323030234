import React from "react";
import { Link } from "react-router-dom";
import { AnimationOnScroll } from "react-animation-on-scroll";

const Footer = () => {
  return (
    <footer className="footer">
      <section className="footer__top">
        <div className="footer__company-info">
          <div className="footer__logo-box u-margin-bottom-large">
            <AnimationOnScroll animateIn="animate__bounceIn">
              <img
                src="/img/didomi-logo.png"
                alt="sample-logo"
                className="footer__logo"
              />
            </AnimationOnScroll>
          </div>
          <address className="footer__contact-info">
            6, Soji Adepegba close Allen Avenue, <br />
            Ikeja Lagos Nigeria. <br />
            <a href="tel:+2348071167839">807 116 7839</a>
            <br />
            <a href="tel:+2349156377772">915 637 7772</a>
            <br />
            <a href="mailto: info@didomiconsortium.com">
              info@didomiconsortium.com
            </a>
          </address>
        </div>
        <div className="footer__nav">
          <h5 className="footer__header">Useful Links</h5>
          <ul className="footer__items">
            <li className="footer__item">
              <a as={Link} href="/" className="footer__links">
                Home
              </a>
            </li>
            <li className="footer__item">
              <a as={Link} href="/didomi-safety" className="footer__links">
                Didomi Safety
              </a>
            </li>
            <li className="footer__item">
              <a as={Link} href="/didomi-travel" className="footer__links">
                Didomi Travel
              </a>
            </li>
            <li className="footer__item">
              <a as={Link} href="/apply-now" className="footer__links">
                Apply
              </a>
            </li>
            <li className="footer__item">
              <a as={Link} href="/technology" className="footer__links">
                Didomi Technology
              </a>
            </li>
            <li className="footer__item">
              <a as={Link} href="/contact-us" className="footer__links">
                Contact Us
              </a>
            </li>
          </ul>
        </div>
        <div className="footer__nav">
          <h5 className="footer__header">Didomi Partners</h5>
          <ul className="footer__items">
            <li className="footer__item">
              <a
                as={Link}
                href="/didomi-travel?section=institution"
                className="footer__links"
              >
                Canada Universities
              </a>
            </li>
            <li className="footer__item">
              <a
                as={Link}
                href="/didomi-travel?section=institution"
                className="footer__links"
              >
                UK Universities
              </a>
            </li>
            <li className="footer__item">
              <a
                as={Link}
                href="/didomi-travel?section=institution"
                className="footer__links"
              >
                USA Universities
              </a>
            </li>
            <li className="footer__item">
              <a
                as={Link}
                href="/didomi-travel?section=institution"
                className="footer__links"
              >
                Australia Universities
              </a>
            </li>
            <li className="footer__item">
              <a
                as={Link}
                href="/didomi-travel?section=institution"
                className="footer__links"
              >
                Germany Universities
              </a>
            </li>
          </ul>
        </div>
        <div className="footer__nav">
          <h5 className="footer__header">Institutions</h5>
          <ul className="footer__items">
            <li className="footer__item">
              <a
                href="https://www.port.ac.uk/study/courses"
                className="footer__links"
                target="_blank"
                rel="noreferrer"
              >
                University of Portsmouth
              </a>
            </li>
            <li className="footer__item">
              <a
                href="https://www.northampton.ac.uk/study/courses-by-subject/"
                className="footer__links"
                target="_blank"
                rel="noreferrer"
              >
                University of Southampton
              </a>
            </li>
            <li className="footer__item">
              <a
                href="https://www.westcliff.edu/admissions/"
                className="footer__links"
                target="_blank"
                rel="noreferrer"
              >
                Westcliff University
              </a>
            </li>
            <li className="footer__item">
              <a
                href="https://www.utoronto.ca/"
                className="footer__links"
                target="_blank"
                rel="noreferrer"
              >
                Toronto West University
              </a>
            </li>
            <li className="footer__item">
              <a
                href="https://www.utwente.nl/en/"
                className="footer__links"
                target="_blank"
                rel="noreferrer"
              >
                University of Twente
              </a>
            </li>
          </ul>
        </div>
      </section>
      <section className="footer__bottom">
        <div className="footer__icons">
          <a
            href="https://web.facebook.com/didomicompanylimited/"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa-brands fa-facebook footer__icon"></i>
          </a>
          <a
            href="https://www.instagram.com/didomicompanylimited/"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa-brands fa-instagram footer__icon"></i>
          </a>
          <a
            href="https://twitter.com/DidomiLimited"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa-brands fa-twitter-square footer__icon"></i>
          </a>
          <a
            href="https://www.linkedin.com/in/didomicompanylimited"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa-brands fa-linkedin footer__icon"></i>
          </a>
        </div>
        <div className="footer__copyright">
          &copy; 2022, Didomi Company Limited
        </div>
      </section>
    </footer>
  );
};

export default Footer;
