import React from "react";

const HomeCarousel = () => {
  return (
    <div className="carousel-wrapper">
      <div className="carousel-container">
        <div className="carousel">
          <div className="image-one"></div>
          <div className="image-two"></div>
          <div className="image-three"></div>
        </div>
      </div>
    </div>
  );
};

export default HomeCarousel;
