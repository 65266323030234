import React, { useState, useRef, useEffect } from "react";

const SectionSix = () => {
  const divRef = useRef(null);

  const [inView, setInView] = useState(null);

  function handleScroll() {
    const bounding = divRef.current.getBoundingClientRect();
    const windowHeight =
      window.innerHeight || document.documentElement.clientHeight;

    const showOnHeight = windowHeight / 5;

    // console.log(showOnHeight);
    // console.log(windowHeight);

    // console.log("bounding 1, top:", bounding.top);
    // console.log("bounding 1, bottom:", bounding.bottom);

    // Detect when element is fully visible in view port
    if (
      bounding.left >= 0 &&
      bounding.top <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <=
        (window.innerWidth || document.documentElement.clientWidth)
    ) {
      //   console.log("Element is in the viewport!");

      if (showOnHeight >= bounding.top && windowHeight < bounding.bottom) {
        setInView("show-6-div-1");
      } else if (
        showOnHeight <= bounding.top &&
        windowHeight < bounding.bottom
      ) {
        setInView("hide-6-div-1");
      } else if (windowHeight > bounding.bottom) {
        setInView("hide-6-div-1");
      }
    } else {
      //   console.log("Element is NOT in the viewport!");
      setInView("hide-6-div-1");
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`tech-sect-6`} ref={divRef}>
      <div className={`tech-sect-6__div-1-imaginary`}>
        <h4 className="tech-sect-6__text-sub">DIDOMI TECHNOLOGY</h4>
        <h2 className="tech-sect-6__text-main heading-secondary-white">
          Our Strategic Build Process
        </h2>
      </div>
      <div className={`tech-sect-6__div-1 ${inView}`}>
        <h4 className="tech-sect-6__text-sub">DIDOMI TECHNOLOGY</h4>
        <h2 className="tech-sect-6__text-main heading-secondary-white">
          Our Strategic Build Process
        </h2>
      </div>

      <div className="tech-sect-6__div-2">
        <h2 className="heading-tertiary-white u-margin-bottom-small">
          I. Discovery
        </h2>
        <p className="tech-sect-6__paragraph u-margin-bottom-medium">
          Our goal is to fully understand each client's business and the
          environment in which it operates. We look to completely understand the
          target audience and how they will interact with the products we
          deliver.
        </p>
        <h2 className="heading-tertiary-white u-margin-bottom-small">
          II. Strategize
        </h2>
        <p className="tech-sect-6__paragraph u-margin-bottom-medium">
          Our planning process turns research into a clear set of action items
          to meet business goals. We take this information to build the
          blueprint to drive more traffic and convert web visitors into web
          leads.
        </p>
        <h2 className="heading-tertiary-white u-margin-bottom-small">
          III. Agreement
        </h2>
        <p className="tech-sect-6__paragraph u-margin-bottom-medium">
          Commercials are agreed.
        </p>
        <h2 className="heading-tertiary-white u-margin-bottom-small">
          IV. Delivery
        </h2>
        <p className="tech-sect-6__paragraph u-margin-bottom-medium">
          Product Management and Developers come up with a timeline for
          Delivery.
        </p>
        <h2 className="heading-tertiary-white u-margin-bottom-small">
          V. Execute
        </h2>
        <p className="tech-sect-6__paragraph u-margin-bottom-medium">
          Our dedicated team of designers use their gained knowledge of your
          company to create visually-engaging designs with the overall user
          experience in mind. We're proud of every project we ship and are
          confident that you and your team will be as well.
        </p>
        <h2 className="heading-tertiary-white u-margin-bottom-small">
          VI. Launch
        </h2>
        <p className="tech-sect-6__paragraph u-margin-bottom-medium">
          During the Implementation, our job is to translate creativity into a
          full program that goes live. Implementation often includes an array of
          services, which can include design, development and search marketing.
        </p>
        <h2 className="heading-tertiary-white u-margin-bottom-small">
          VII. Evolve
        </h2>
        <p className="tech-sect-6__paragraph u-margin-bottom-medium">
          Last but not least, our task is to use scientific metrics to track and
          analyze campaign performance. This helps us easily identify what
          worked and what did not, we then initiate new strategies to maximize
          your business goals.
        </p>
        <h2 className="heading-tertiary-white u-margin-bottom-small">
          VIII. Support
        </h2>
        <p className="tech-sect-6__paragraph u-margin-bottom-medium">
          We support your Project till you are happy even after Delivery
        </p>
      </div>
    </div>
  );
};

export default SectionSix;
