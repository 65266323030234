import React from "react";
import Meta from "./common/Meta";
import Institutions from "./Institutions";
import Services from "./Services";

const Didomi = () => {
  return (
    <div className="didomi">
      <Meta title={"Education | Didomi Company Limited"} />
      <div className="didomi__header">
        <div className="didomi__header-text">
          <h1 className="didomi__header-text-main">DIDOMI TRAVEL</h1>
          <p className="didomi__header-text-sub">
            Didomi Travels provide a wide range of services to support you with
            your international education journey. If your thoughts are on
            studying overseas, Didomi Travels will get you there. From course
            information to career advice, we are dedicated to you and your
            international destination.
          </p>
        </div>
      </div>

      <Services />
      <Institutions />
    </div>
  );
};

export default Didomi;
