import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";

const TopHeader = ({ user }) => {
  const [submenu, setSubmenu] = useState(false);

  return (
    <div className="top-header">
      <nav className="top-header__nav-list">
        <div className="top-header__nav-item">
          <div className="top-header__btn">
            <a as={Link} href="/apply-now">
              Apply now
            </a>
          </div>
        </div>
      </nav>

      <nav className="top-header__nav-list">
        <div className="top-header__nav-item">
          {!user && (
            <div className="top-header__btn">
              <a as={Link} href="/login">
                Log in
              </a>
            </div>
          )}
          {user && (
            <div className="top-header__user-avatar">
              <div className="top-header__user-avatar-icon">
                <i
                  className="fa-solid fa-user top-header__user-avatar-user"
                  onClick={() => setSubmenu(!submenu)}
                ></i>
              </div>
              <div
                className={`top-header__user-avatar-submenu ${
                  submenu ? "show-user-submenu" : "hide-user-submenu"
                }`}
              >
                <ul>
                  <li>{`${user.firstName} ${user.lastName}`}</li>
                  <li>
                    <NavLink
                      to="/dashboard"
                      onClick={() => setSubmenu(!submenu)}
                    >
                      Dashboard
                    </NavLink>
                  </li>
                  <li className="dis-tab-port">
                    <NavLink
                      to="/dashboard/edit-profile"
                      onClick={() => setSubmenu(!submenu)}
                    >
                      Edit Profile
                    </NavLink>
                  </li>
                  <li className="dis-tab-port">
                    <NavLink
                      to="/dashboard"
                      onClick={() => setSubmenu(!submenu)}
                    >
                      Reset Password
                    </NavLink>
                  </li>
                  {user.isAdmin && (
                    <>
                      <li className="dis-tab-port">
                        <NavLink
                          to="/dashboard/applicants"
                          onClick={() => setSubmenu(!submenu)}
                        >
                          Applicants
                        </NavLink>
                      </li>
                      <li className="dis-tab-port">
                        <NavLink
                          to="/dashboard/email"
                          onClick={() => setSubmenu(!submenu)}
                        >
                          Send Email
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/dashboard"
                          onClick={() => setSubmenu(!submenu)}
                        >
                          Settings
                        </NavLink>
                      </li>
                    </>
                  )}

                  <li>
                    <a as={Link} href="/logout">
                      Log out
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </nav>
    </div>
  );
};

export default TopHeader;
