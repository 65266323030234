import React, { useState } from "react";
import { toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";
import Joi from "joi-browser";
import { receiveEmail } from "../../services/mailService";

const PopupContactForm = ({ openForm, handleOpenForm }) => {
  let [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const schema = {
    name: Joi.string().required().label("Full name"),
    email: Joi.string().required().email().label("Email"),
    subject: Joi.string().required().label("Subject"),
    message: Joi.string().required().label("Message"),
  };

  const messageSender = async (mail) => {
    try {
      const { data } = await receiveEmail(mail);
      setLoading(false);
      handleOpenForm(); // close form
      toast(`${data.message}`, { className: "toast-style" });
    } catch (ex) {
      setLoading(false);
      handleOpenForm(); // close form
      toast(ex.response.data.message, { className: "toast-style" });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setErrorMessage("");

    // console.log("submitted");

    const obj = {
      name,
      email,
      subject,
      message,
    };

    // console.log(formRef.current);

    const { error } = Joi.validate(obj, schema);

    if (error) {
      setErrorMessage(error.details[0].message);
    } else {
      setErrorMessage("");
      setLoading(true);
      messageSender(obj);
    }
  };

  const showForm = openForm ? "show-form" : null;

  return (
    <div className={`popup ${showForm}`}>
      <div className="contact-form__container">
        <i
          className="fa-solid fa-xmark contact-form__container-close__icon"
          onClick={() => handleOpenForm()}
        ></i>
        <h4 className="form-heading">Contact us</h4>
        <form className="contact-form" onSubmit={(e) => handleSubmit(e)}>
          <div className="contact-form__row">
            <label htmlFor="name">Full name:</label>
            <input
              type="text"
              value={name}
              name="name"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="contact-form__row">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              value={email}
              name="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="contact-form__row">
            <label htmlFor="subject">Subject:</label>
            <input
              type="text"
              value={subject}
              name="subject"
              onChange={(e) => setSubject(e.target.value)}
            />
          </div>
          <div className="contact-form__row">
            <label htmlFor="message">Message:</label>
            <textarea
              rows={5}
              value={message}
              name="message"
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <div className="contact-form__row">
            <ClipLoader color="#54aa01" loading={loading} />
            <p>{errorMessage}</p>
            {!loading && <button type="submit">Send</button>}
          </div>
        </form>
      </div>
    </div>
  );
};

export default PopupContactForm;
