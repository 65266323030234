import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import auth from "./services/authService";
import TopHeader from "./components/common/TopHeader";
import Header from "./components/common/Header";
import OutsideClickHandler from "./components/common/OutsideClickHandler";
import Sidebar from "./components/common/Sidebar";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Travel from "./components/Travel";
import Scholarship from "./components/Scholarship";
import OurTeam from "./components/OurTeam";
import ContactUs from "./components/ContactUs";
import PopupContactForm from "./components/common/PopupContactForm";
import PopupContactButton from "./components/common/PopupContactButton";
import Safety from "./components/Safety";
import ApplyNow from "./components/ApplyNow";
import Dashboard from "./components/dashboard/Dashboard";
import Login from "./components/Login";
import Logout from "./components/Logout";
import About from "./components/About";
import Profile from "./components/dashboard/Profile";
import EditProfile from "./components/dashboard/EditProfile";
import Applicants from "./components/dashboard/Applicants";
import AdminEditApplicant from "./components/dashboard/AdminEditApplicant";
import AdminViewApplicant from "./components/dashboard/AdminViewApplicant";
import ProtectedRoute from "./components/protected/ProtectedRoute";
import RequiredAdmin from "./components/protected/RequiredAdmin";
import SendEmail from "./components/dashboard/SendEmail";
import SearchResult from "./components/SearchResult";
import SearchPopup from "./components/common/SearchPopup";
import ResetPassword from "./components/ResetPassword";
import NewPassword from "./components/NewPassword";
import Technology from "./components/pages/technology/Technology";

function App() {
  const [showSidebar, setShowSidebar] = useState(false);
  const [showSearchPopup, setShowShowSearchPopup] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    const data = auth.getCurrentUser();
    setUserDetails({ user: data });
  }, []);

  const handleShowSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const handleShowSearchPopup = () => {
    setShowShowSearchPopup(!showSearchPopup);
  };

  const handleOpenForm = () => {
    setOpenForm(!openForm);
    // console.log("clicked");
  };

  return (
    <div className="">
      <TopHeader user={userDetails.user} />
      <Header
        handleShowSidebar={handleShowSidebar}
        handleShowSearchPopup={handleShowSearchPopup}
      />
      <ToastContainer />
      <OutsideClickHandler onOutsideClick={() => setShowSidebar(false)}>
        <Sidebar showSidebar={showSidebar} />
      </OutsideClickHandler>

      <main className="content">
        <Routes>
          <Route path="/logout" element={<Logout />} />
          <Route path="/login" element={<Login />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/new-password/:token" element={<NewPassword />} />
          <Route path="/apply-now" element={<ApplyNow />} />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard user={userDetails.user} />
              </ProtectedRoute>
            }
          >
            <Route path="/dashboard" element={<Profile />}></Route>
            <Route
              path="/dashboard/edit-profile"
              element={<EditProfile />}
            ></Route>
            <Route
              path="/dashboard/applicants"
              element={
                <RequiredAdmin>
                  {" "}
                  <Applicants />
                </RequiredAdmin>
              }
            ></Route>
            <Route
              path="/dashboard/applicant/:id"
              element={
                <RequiredAdmin>
                  <AdminViewApplicant />
                </RequiredAdmin>
              }
            ></Route>
            <Route
              path="/dashboard/applicant/:id/edit"
              element={
                <RequiredAdmin>
                  <AdminEditApplicant />
                </RequiredAdmin>
              }
            ></Route>
            <Route
              path="/dashboard/email"
              element={
                <RequiredAdmin>
                  <SendEmail />
                </RequiredAdmin>
              }
            ></Route>
          </Route>
          <Route path="/search" element={<SearchResult />} />
          <Route path="/technology" element={<Technology />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/didomi-safety" element={<Safety />} />
          <Route path="/our-team" element={<OurTeam />} />
          <Route path="/scholarship" element={<Scholarship />} />
          <Route path="/didomi-travel" element={<Travel />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </main>
      <SearchPopup
        showSearchPopup={showSearchPopup}
        handleShowSearchPopup={handleShowSearchPopup}
      />
      <PopupContactForm openForm={openForm} handleOpenForm={handleOpenForm} />
      <PopupContactButton handleOpenForm={handleOpenForm} />
      <Footer />
    </div>
  );
}

export default App;
