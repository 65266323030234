import React, { useState, useRef, useEffect } from "react";

const SectionSeven = () => {
  const sectRef = useRef(null);
  const [inView, setInView] = useState(null);

  function handleScroll() {
    // console.log("scrolling");

    const bounding = sectRef.current.getBoundingClientRect();
    // console.log(bounding);

    // Detect when element is visible in view port (partially)
    if (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.top <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <=
        (window.innerWidth || document.documentElement.clientWidth)
    ) {
      //   console.log("Element is in the viewport!");
      setInView("show-tech-sect-3");
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className={`tech-sect-7 tech-sect-3 ${inView}`} ref={sectRef}>
      <div className="tech-sect-3__div-1">
        <h4 className="tech-sect-3__text-sub">WORK WITH US</h4>
        <h2 className="tech-sect-3__text-main heading-secondary-white">
          Let's work together to build something great.
        </h2>
        <span className="tech-sect-2__btn tech-sect-7__btn-center">
          <button className="j-btn u-margin-top-medium">
            <a
              href="https://wa.me/message/4PWF5RH2M5TUA1"
              target="_blank"
              rel="noreferrer"
            >
              CONTACT US
            </a>{" "}
          </button>
        </span>
      </div>
      <div className="tech-sect-3__div-2 tech-sect-7__img-none">
        <img src="img/didomi-logo.png" alt="didomi logo" />
      </div>
    </div>
  );
};

export default SectionSeven;
