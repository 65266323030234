import React from "react";
import { useNavigate } from "react-router-dom";

const SearchPopup = ({
  showSearchPopup,
  handleShowSearchPopup: setSearchPopup,
}) => {
  const show_search_popup = showSearchPopup ? "show-search-popup" : null;

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    // console.log(e.target.search.value);
    const keyword = e.target.search.value;

    // close the search popup
    setSearchPopup();
    navigate(`/search?institution=${keyword}`);
  };
  return (
    <div className={`search-popup ${show_search_popup}`}>
      <form className="search-popup__form" onSubmit={(e) => handleSubmit(e)}>
        <input
          type="text"
          className="search-popup__input"
          name="search"
          placeholder="Search schools"
        />
        <button className="search-popup__button" type="submit">
          <i className="fa-solid fa-magnifying-glass search-popup__search"></i>
        </button>
        <i
          className="bi bi-x-lg search-popup__close"
          onClick={() => setSearchPopup()}
        ></i>
      </form>
    </div>
  );
};

export default SearchPopup;
