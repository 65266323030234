export const faq = [
  {
    main: "What are the application process turnaround times?",
    sub: "10 working days",
    showDropdown: false,
  },
  {
    main: "Do I need IELTS before I can apply?",
    sub: "No. You only need A - C in your WASSCE Result and you are good to go.",
    showDropdown: false,
  },
  {
    main: "Do I have to pay all my tuition fees in Nigeria before I can travel?",
    sub: "No. you will be asked to make an initial deposit of £2,000 or more before CAS and the rest will be paid in instalments when you arrive in the UK.",
    showDropdown: false,
  },
  {
    main: "Do you assist with visa application process?",
    sub: "Yes we will prepare/counsel you before you apply for your visa.",
    showDropdown: false,
  },
  {
    main: "How long does the visa process take?",
    sub: "It’s normally 15 working days or within a week for priority visa application.",
    showDropdown: false,
  },
];
