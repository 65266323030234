import React from "react";
import { Link } from "react-router-dom";

const SectionTwo = () => {
  return (
    <div className="section-2">
      <div className="section-2__heading-box">
        <h2 className="heading-secondary">Looking to study abroad?</h2>
      </div>

      <div className="section-2__row-1">
        <div className="section-2__row-1-img section-2__row-1-img-1">
          {" "}
          <div className="section-2__row-1-img-text">undergraduate</div>
          <div className="section-2__row-1-img-sub-text">Think differently</div>
        </div>

        <div className="section-2__row-1-img section-2__row-1-img-2">
          <div className="section-2__row-1-img-text">postgraduate</div>
          <div className="section-2__row-1-img-sub-text">
            Trust in your talent
          </div>
        </div>
      </div>
      <div className="section-2__special-box">
        <h2 className="section-2__special-box-heading">
          <a as={Link} href="/apply-now">
            Let Didomi counsellors help you!
          </a>
        </h2>
      </div>
    </div>
  );
};

export default SectionTwo;
