import React from "react";
import { Link } from "react-router-dom";

const SectionThree = () => {
  return (
    <section className="section-3">
      <div className="u-center-text u-margin-top-large u-margin-bottom-small">
        <h2 className="heading-secondary-medium">MEET OUR EXPERT</h2>
      </div>
      <div className="section-3__heading-border"></div>
      <div className="section-3__heading-sub">
        <p className="u-center-text paragraph-primary">
          You can book your appointment with Didomi today. We have certified and
          competent counselors who will guide you through in choosing the best
          academic career.
        </p>
      </div>
      <div className="section-3__row-1">
        <div className="section-3__row-1-part-1">
          <img src="img/didomi-30.jpg" alt="didomi" />
        </div>
        <div className="section-3__row-1-part-2">
          <img src="img/didomi-31.jpg" alt="didomi" />
        </div>
      </div>

      <div className="u-center-text u-margin-top-large u-margin-bottom-small">
        <h2 className="heading-secondary-medium">Available Universities</h2>
      </div>
      <div className="section-3__heading-border"></div>
      <div className="section-3__heading-sub">
        <p className="u-center-text paragraph-primary">
          Apply to leading universities in different countries which Didomi is
          in partnership with. Didomi counsellors will help you get ready for
          your fresh life in whichever country you choose to study with
          pre-departure support.
        </p>
      </div>

      <div className="section-3__row-2">
        <div className="section-3__row-2-col">
          <a as={Link} href="/didomi-travel?section=institution">
            <div className="section-3__row-2-img section-3__row-2-img-1">
              <div className="section-3__row-2-img-text">United Kingdom</div>
            </div>
          </a>
          <a as={Link} href="/didomi-travel?section=institution">
            <div className="section-3__row-2-img section-3__row-2-img-4">
              <div className="section-3__row-2-img-text">Canada</div>
            </div>
          </a>
        </div>
        <div className="section-3__row-2-col">
          <a as={Link} href="/didomi-travel?section=institution">
            <div className="section-3__row-2-img section-3__row-2-img-2">
              <div className="section-3__row-2-img-text">United State</div>
            </div>
          </a>
          <a as={Link} href="/didomi-travel?section=institution">
            <div className="section-3__row-2-img section-3__row-2-img-5">
              <div className="section-3__row-2-img-text">Germany</div>
            </div>
          </a>
        </div>
        <div className="section-3__row-2-col">
          <a as={Link} href="/didomi-travel?section=institution">
            <div className="section-3__row-2-img section-3__row-2-img-3">
              <div className="section-3__row-2-img-text">Australia</div>
            </div>
          </a>
          <div className="section-3__row-2-img section-3__row-2-img-6">
            <div className="section-3__row-2-text">
              Once we find the fitted course, we will assist you with the
              application process, documentation, acceptance and so much more.
            </div>
          </div>
        </div>
      </div>
      <div className="section-3__row-3">
        <div className="section-3__special-box u-center-text">
          <h2 className="section-3__special-box-heading">
            PACK AND GET READY TO GO!
          </h2>
        </div>
        <div className="apply__btn">
          <a as={Link} href="/apply-now">
            Apply now
          </a>
        </div>
      </div>
    </section>
  );
};

export default SectionThree;
