import React from "react";
import { Link } from "react-router-dom";
// import OutsideClickHandler from "./OutsideClickHandler";

const Sidebar = ({ showSidebar }) => {
  const show_sidebar = showSidebar ? "show-sidebar" : null;
  // const [submenu_1, setSubmenu_1] = useState(null);
  // const [submenu_2, setSubmenu_2] = useState(null);

  return (
    <div className="sidebar-container">
      <div className={`sidebar ${show_sidebar}`}>
        <nav className="sidebar__nav">
          <ul className="sidebar__list">
            <div>
              <li className="sidebar__item">
                <a as={Link} href="/" className="sidebar__link">
                  Home
                </a>
              </li>
              <div className="sidebar-border"></div>
            </div>

            <div>
              <li className="sidebar__item">
                <a as={Link} href="/about-us" className="sidebar__link">
                  About
                </a>
              </li>
              <div className="sidebar-border"></div>
            </div>

            <div>
              <li className="sidebar__item">
                <a as={Link} href="/didomi-safety" className="sidebar__link">
                  Safety
                </a>
              </li>
              <div className="sidebar-border"></div>
            </div>

            <div>
              <li className="sidebar__item">
                <a as={Link} href="/didomi-travel" className="sidebar__link">
                  Travel
                </a>
              </li>
              <div className="sidebar-border"></div>
            </div>

            <div>
              <li className="sidebar__item">
                <a as={Link} href="/scholarship" className="sidebar__link">
                  Scholarship
                </a>
              </li>
              <div className="sidebar-border"></div>
            </div>
            <div>
              <li className="sidebar__item">
                <a as={Link} href="/technology" className="sidebar__link">
                  Technology
                </a>
              </li>
              <div className="sidebar-border"></div>
            </div>

            <div>
              {" "}
              <li className="sidebar__item">
                <a as={Link} href="/contact-us" className="sidebar__link">
                  Contact Us
                </a>
              </li>
            </div>
          </ul>
        </nav>
      </div>{" "}
    </div>
  );
};

export default Sidebar;
