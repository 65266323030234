import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Joi from "joi-browser";
import * as userService from "../services/userService";
import auth from "../services/authService";
import Meta from "./common/Meta";

const ApplyNow = () => {
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [level, setLevel] = useState("");
  const [password, setPassword] = useState("");
  const [policy, setPolicy] = useState("");
  const [contactMe, setContactMe] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const schema = {
    firstName: Joi.string().required().label("First name"),
    lastName: Joi.string().required().label("Last name"),
    email: Joi.string().required().email().label("Email"),
    phone: Joi.number().required().label("Phone"),
    country: Joi.string().required().label("Country"),
    level: Joi.string().required().label("Level"),
    password: Joi.string().required().label("Password"),
    policy: Joi.string().required().label("Policy"),
    contactMe: Joi.string().required().label("Contact me"),
  };

  const doSubmit = async (userDetails) => {
    try {
      // Call the server
      const { data } = await userService.register(userDetails);
      // console.log(data);
      auth.loginWithJwt(data.token);
      window.location = "/dashboard"; // go to dashboard
    } catch (ex) {
      setErrorMessage(ex.response.data.message);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      firstName,
      lastName,
      email,
      phone,
      country,
      level,
      password,
      policy,
      contactMe,
    };

    const { error } = Joi.validate(data, schema);

    if (error) {
      // console.log(error.details[0].path[0]);
      if (error.details[0].path[0] === "policy") {
        setErrorMessage("You must agree to our privacy policy");
      } else if (error.details[0].path[0] === "contactMe") {
        setErrorMessage("You must agree to receive sms or email");
      } else setErrorMessage(error.details[0].message);
    } else {
      setErrorMessage("");
      doSubmit(data);
    }
  };

  useEffect(() => {
    if (auth.getCurrentUser()) navigate("/dashboard");
  }, [navigate]);

  return (
    <div className="apply-page">
      <Meta title={"Apply | Didomi Company Limited"} />
      <div className="apply-page__row">
        <div className="apply-page__info">
          <h2 className="heading-tertiary-primary"> Didomi Company Limited </h2>{" "}
          <p className="paragraph">
            Book your appointment with Didomi certified education counsellors
            who will guide you to find the right course that best matches your
            academic needs and career ambition.
          </p>{" "}
        </div>{" "}
        <div className="apply-page__form-container">
          <form
            className="form-content"
            onSubmit={(e) => handleSubmit(e)}
            noValidate
          >
            <input
              type="text"
              value={firstName}
              name="firstName"
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="First name"
            />
            <br />
            <input
              type="text"
              value={lastName}
              name="lastName"
              onChange={(e) => setLastName(e.target.value)}
              placeholder="Last name"
            />
            <br />
            <input
              type="email"
              value={email}
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email address"
            />
            <br />
            <input
              type="phone"
              value={phone}
              name="phone"
              onChange={(e) => setPhone(e.target.value)}
              placeholder="Phone"
            />
            <br />
            <select
              name="institution"
              className="form-content__select"
              onChange={(e) => setCountry(e.target.value)}
              defaultValue=""
            >
              <option value="" disabled hidden>
                Choose Country...
              </option>
              <option value="United Kingdom">UK</option>
              <option value="Canada">Canada</option>
              <option value="United State of America">USA</option>
              <option value="Germany">Germany</option>
              <option value="Australia">Australia</option>
            </select>
            <br />
            <select
              name="institution"
              className="form-content__select"
              onChange={(e) => setLevel(e.target.value)}
              defaultValue=""
            >
              <option value="" disabled hidden>
                Choose Level...
              </option>
              <option value="English Language School">
                English Language School
              </option>
              <option value="Undergraduate">Undergraduate</option>
              <option value="Postgraduate">Postgraduate</option>
              <option value="Doctorate">Doctorate</option>
            </select>
            <input
              type="password"
              value={password}
              name="password"
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />
            <p className="">
              Didomi company will not share your details with others without
              your consent:
            </p>
            <p className="form-content__policy u-margin-top-small">
              <input
                type="checkbox"
                name="policy"
                value="Agree"
                onChange={(e) => setPolicy(e.target.value)}
              />{" "}
              I agree to Didomi terms and privacy policy
            </p>{" "}
            <p className="form-content__policy">
              <input
                type="checkbox"
                name="contactMe"
                value="Agree"
                onChange={(e) => setContactMe(e.target.value)}
              />
              Please contact me by email or SMS to assist my enquiry
            </p>{" "}
            <p className="form-content__error-message"> {errorMessage} </p>{" "}
            <button type="submit"> Submit </button>{" "}
            <p className="">Already apply? Login to view dashboard</p>
            <div className="apply-btn">
              <a as={Link} href="/login">
                Log in
              </a>{" "}
            </div>{" "}
          </form>{" "}
        </div>{" "}
      </div>{" "}
    </div>
  );
};

export default ApplyNow;
