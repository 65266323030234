import React from "react";
import Meta from "./../../common/Meta";
import Section from "./Section";
import SectionFive from "./SectionFive";
import SectionFour from "./SectionFour";
import SectionOne from "./SectionOne";
import SectionSeven from "./SectionSeven";
import SectionSix from "./SectionSix";
import SectionThree from "./SectionThree";
import SectionTwo from "./SectionTwo";

const Technology = () => {
  return (
    <div className="technology">
      <Meta title={"Technology | Didomi Company Limited"} />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <SectionFive />
      <SectionSix />
      <Section />
      <SectionSeven />
    </div>
  );
};

export default Technology;
