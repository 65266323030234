import React from "react";

const SearchItems = ({ result, keyword }) => {
  return (
    <div>
      {result.length > 0 ? (
        result.map((item) => (
          <div key={item._id} className="search-result__item">
            <div className="search-result__content-heading-1">
              <h3 className="heading-tertiary-primary">{item.country}</h3>
            </div>
            {item.schools.map((school, index) => (
              <div key={index} className="search-result__content-heading-2">
                <h3 className="heading-tertiary-normal">
                  <a href={school.url} target="_blank" rel="noreferrer">
                    {school.name}
                  </a>
                </h3>
              </div>
            ))}
          </div>
        ))
      ) : (
        <p className="paragraph">No institution found for {keyword}</p>
      )}
    </div>
  );
};

export default SearchItems;
