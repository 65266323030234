import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Joi from "joi-browser";
import Meta from "./common/Meta";
import auth from "../services/authService";

const Login = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const schema = {
    email: Joi.string().required().email().label("Email"),
    password: Joi.string().required().label("Password"),
  };

  const doSubmit = async () => {
    try {
      // Call the server
      await auth.login(email, password);
      window.location = "dashboard"; // go to dashboard
    } catch (ex) {
      setErrorMessage(ex.response.data.message);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { error } = Joi.validate(
      {
        email,
        password,
      },
      schema
    );

    if (error) {
      setErrorMessage(error.details[0].message);
    } else {
      console.log("submitted");
      doSubmit();
    }
  };

  useEffect(() => {
    if (auth.getCurrentUser()) navigate("/dashboard");
  }, [navigate]);

  return (
    <div className="login-page">
      <Meta title={"Login | Didomi Company Limited"} />
      <div className="login-page__row">
        <div className="login-page__info">
          <h2 className="heading-tertiary-primary">Didomi Company Limited</h2>
          <p className="paragraph">
            Apply to leading universities in different countries which Didomi is
            in partnership with. Didomi counsellors will help you get ready for
            your fresh life in whichever country you choose to study with
            pre-departure support.
          </p>
        </div>
        <div className="login-page__form-container">
          <form
            className="form-content"
            onSubmit={(e) => handleSubmit(e)}
            noValidate
          >
            <input
              type="email"
              value={email}
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email address"
            />
            <br />
            <input
              type="password"
              value={password}
              name="password"
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />
            <br />
            <p className="form-content__error-message">{errorMessage}</p>
            <button type="submit">Log In</button>
            <div className="form-content__forgot-password">
              <a href="/reset-password">Forgotten password?</a>
            </div>
            <div className="apply-btn">
              <a as={Link} href="/apply-now">
                Apply now
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
