import React from "react";
import Meta from "../common/Meta";
import UserDashboard from "./UserDashboard";
import AdminDashboard from "./AdminDashboard";

export default function Dashboard({ user }) {
  return (
    <div>
      <Meta title={"Dashboard | Didomi Company Limited"} />
      {user && <>{user.isAdmin ? <AdminDashboard /> : <UserDashboard />}</>}
    </div>
  );
}
