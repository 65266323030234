import React from "react";
import TheCard from "./common/TheCard";

const Services = () => {
  return (
    <section className="didomi-service">
      <div className="didomi-service__header">
        <h2 className="heading-secondary-white">Why Choose Didomi?</h2>
      </div>
      <div className="didomi-service__heading-border"></div>
      <p className="paragraph-white didomi-service__paragraph">
        As a pathfinder in international education, we are truly overall in
        scale and experience.
      </p>

      <div className="didomi-service__row">
        <div className="the-card__mr the-card__container">
          <TheCard
            title={"Visa Counselling and Application"}
            text={
              "We will put you through the application process and help you prepare the right documents for your visa submission."
            }
            imageClass={"the-card__img-1"}
          />
        </div>
        <div className="the-card__container">
          <TheCard
            title={"Course Selection"}
            text={
              "We can help with personal course advice that is best suited to you."
            }
            imageClass={"the-card__img-2"}
          />
        </div>
      </div>
      <div className="didomi-service__row">
        <div className="the-card__mr the-card__container">
          <TheCard
            title={"Bookings and Air Ticketing"}
            text={
              "We help with booking, inexpensive air tickets for our clients."
            }
            imageClass={"the-card__img-3"}
          />
        </div>
        <div className="the-card__container">
          <TheCard
            title={"Pre-departure Support"}
            text={
              "Get off the plane feeling vibrant and ready for the adventure ahead with our pre-departure assistance."
            }
            imageClass={"the-card__img-4"}
          />
        </div>
      </div>
    </section>
  );
};

export default Services;
