import React from "react";
import Meta from "./common/Meta";

const OurTeam = () => {
  return (
    <section className="our-team">
      <Meta title={"Our team | Didomi Company Limited"} />
      <div className="our-team__header">
        <h2 className="our-team__header-text">Our Team</h2>
      </div>

      <div className="our-team__row">
        <div className="our-team__images">
          <div className="image-card image-card__mb">
            <div className="image-card__box">
              <img
                src="img/didomi-20.jpg"
                alt="didomi-20"
                className="image-card__img"
              />
            </div>

            <div className="image-card__title">
              <h3>Member's Name</h3>
            </div>
            <div className="image-card__body">
              <p className="image-card__text">Student Applications Officer</p>
              <div className="image-card__icons">
                <a href="https://facebook.com" target="_blank" rel="noreferrer">
                  <i className="fa-brands fa-facebook image-card__icon"></i>
                </a>
                <a
                  href="https://instagram.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa-brands fa-instagram image-card__icon"></i>
                </a>
                <a href="https://twitter.com" target="_blank" rel="noreferrer">
                  <i className="fa-brands fa-twitter-square image-card__icon"></i>
                </a>
                <a href="https://linkedin.com" target="_blank" rel="noreferrer">
                  <i className="fa-brands fa-linkedin image-card__icon"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="image-card">
            <div className="image-card__box">
              <img
                src="img/didomi-20.jpg"
                alt="didomi-20"
                className="image-card__img"
              />
            </div>

            <div className="image-card__title">
              <h3>Member's Name</h3>
            </div>
            <div className="image-card__body">
              <p className="image-card__text">Student Applications Officer</p>
              <div className="image-card__icons">
                <a href="https://facebook.com" target="_blank" rel="noreferrer">
                  <i className="fa-brands fa-facebook image-card__icon"></i>
                </a>
                <a
                  href="https://instagram.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa-brands fa-instagram image-card__icon"></i>
                </a>
                <a
                  href="https://twitter.com/BetterPlaceLiv1"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa-brands fa-twitter-square image-card__icon"></i>
                </a>
                <a href="https://linkedin.com" target="_blank" rel="noreferrer">
                  <i className="fa-brands fa-linkedin image-card__icon"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="our-team__row">
        <div className="our-team__images">
          <div className="image-card image-card__mb">
            <div className="image-card__box">
              <img
                src="img/didomi-20.jpg"
                alt="didomi-20"
                className="image-card__img"
              />
            </div>

            <div className="image-card__title">
              <h3>Member's Name</h3>
            </div>
            <div className="image-card__body">
              <p className="image-card__text">Student Applications Officer</p>
              <div className="image-card__icons">
                <a href="https://facebook.com" target="_blank" rel="noreferrer">
                  <i className="fa-brands fa-facebook image-card__icon"></i>
                </a>
                <a
                  href="https://instagram.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa-brands fa-instagram image-card__icon"></i>
                </a>
                <a
                  href="https://twitter.com/BetterPlaceLiv1"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa-brands fa-twitter-square image-card__icon"></i>
                </a>
                <a href="https://linkedin.com" target="_blank" rel="noreferrer">
                  <i className="fa-brands fa-linkedin image-card__icon"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="image-card">
            <div className="image-card__box">
              <img
                src="img/didomi-20.jpg"
                alt="didomi-20"
                className="image-card__img"
              />
            </div>

            <div className="image-card__title">
              <h3>Member's Name</h3>
            </div>
            <div className="image-card__body">
              <p className="image-card__text">Student Applications Officer</p>
              <div className="image-card__icons">
                <a href="https://facebook.com" target="_blank" rel="noreferrer">
                  <i className="fa-brands fa-facebook image-card__icon"></i>
                </a>
                <a
                  href="https://instagram.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa-brands fa-instagram image-card__icon"></i>
                </a>
                <a
                  href="https://twitter.com/BetterPlaceLiv1"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa-brands fa-twitter-square image-card__icon"></i>
                </a>
                <a href="https://linkedin.com" target="_blank" rel="noreferrer">
                  <i className="fa-brands fa-linkedin image-card__icon"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurTeam;
