import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Joi from "joi-browser";
import { getUserDetails, updateUserDetails } from "../../services/userService";

const EditProfile = () => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [bio, setBio] = useState("");
  const [country, setCountry] = useState("");
  const [level, setLevel] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const schema = {
    firstName: Joi.string().required().label("First name"),
    lastName: Joi.string().required().label("Last name"),
    phone: Joi.number().required().label("Phone"),
    bio: Joi.string().required().label("Biography"),
    country: Joi.string().required().label("Country"),
    level: Joi.string().required().label("Level"),
  };

  const getData = async () => {
    try {
      const { data } = await getUserDetails();
      setFirstName(data.firstName);
      setLastName(data.lastName);
      setPhone(data.phone);
      setBio(data.bio);
      setCountry(data.country);
      setLevel(data.level);
      // console.log(data);
    } catch (ex) {
      // console.log(ex);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const updateData = async (userDetails) => {
    try {
      await updateUserDetails(userDetails);
      // console.log(data);
      navigate("/dashboard"); // go to dashboard
      // console.log("submitted");
    } catch (ex) {
      setErrorMessage(ex.response.data.message);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      firstName,
      lastName,
      phone,
      bio,
      country,
      level,
    };

    const { error } = Joi.validate(data, schema);

    if (error) {
      // console.log(error.details[0].path[0]);
      setErrorMessage(error.details[0].message);
    } else {
      setErrorMessage("");
      if (window.confirm("Press ok to continue")) {
        updateData(data);
      }
    }
  };

  return (
    <div className="">
      <h3 className="dashboard-main__header">Edit Profile</h3>
      <div className="dashboard-main">
        <div className="dashboard-main__1">
          <form
            className="form-content"
            onSubmit={(e) => handleSubmit(e)}
            noValidate
          >
            <input
              type="text"
              value={firstName}
              name="firstName"
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="First name"
            />
            <br />
            <input
              type="text"
              value={lastName}
              name="lastName"
              onChange={(e) => setLastName(e.target.value)}
              placeholder="Last name"
            />
            <br />
            <input
              type="phone"
              value={phone}
              name="phone"
              onChange={(e) => setPhone(e.target.value)}
              placeholder="Phone"
            />
            <textarea
              rows={5}
              value={bio}
              name="bio"
              onChange={(e) => setBio(e.target.value)}
              placeholder="Bio"
            />
            <br />
            <select
              name="institution"
              className="form-content__select"
              onChange={(e) => setCountry(e.target.value)}
              defaultValue=""
            >
              <option value="" disabled hidden>
                Choose Country...
              </option>
              <option value="United Kingdom">UK</option>
              <option value="Canada">Canada</option>
              <option value="United State of America">USA</option>
              <option value="Germany">Germany</option>
              <option value="Australia">Australia</option>
            </select>
            <br />
            <select
              name="institution"
              className="form-content__select"
              onChange={(e) => setLevel(e.target.value)}
              defaultValue=""
            >
              <option value="" disabled hidden>
                Choose Level...
              </option>
              <option value="English Language School">
                English Language School
              </option>
              <option value="Undergraduate">Undergraduate</option>
              <option value="Postgraduate">Postgraduate</option>
              <option value="Doctorate">Doctorate</option>
            </select>
            <p className="form-content__error-message"> {errorMessage} </p>{" "}
            <button type="submit"> Update </button>{" "}
          </form>{" "}
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
