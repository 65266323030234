import React from "react";
import Meta from "./common/Meta";
import { Link } from "react-router-dom";

const DidomiSafety = () => {
  return (
    <div className="didomi-safety">
      <Meta title={"Safety | Didomi Company Limited"} />
      <div className="didomi-safety__header">
        <h1 className="didomi-safety__header-text">
          Didomi Safety Consultancy
        </h1>
      </div>
      <div className="didomi-safety__section-header u-center-text u-margin-top-medium u-margin-bottom-small">
        <h3 className="heading-secondary">We Prioritize Safety</h3>
      </div>
      <div className="didomi-safety__section">
        <div className="didomi-safety__section-part-1">
          <h4 className="heading-tertiary-normal u-margin-bottom-small u-center-text">
            Didomi
          </h4>
          <p className="paragraph u-margin-bottom-small">
            Didomi Safety Consultancy is a service-oriented Consulting firm.
          </p>
          <p className="paragraph">
            {" "}
            For over some years we’ve been helping companies in ALL sectors go
            from ‘low’ safety to ‘complete’ safety in all spheres of health and
            safety. We know that you might already have SOME safety in place. We
            also know that every company is unique and this makes your
            organization different from your competitor.
          </p>
        </div>
        <div className="didomi-safety__section-part-2">
          <div className="">
            <img src="img/didomi-22.jpg" alt="didomi-22" />
          </div>
        </div>
      </div>

      <div className="didomi-safety__section">
        <div className="didomi-safety__section-part-1">
          <h4 className="heading-tertiary-normal u-margin-bottom-small u-center-text">
            What we do
          </h4>
          <ul className="u-margin-left-small">
            <li className="paragraph">
              We are pleased to work with companies who have existing programs
              in place or with companies that need a custom program developed.
            </li>
            <li className="paragraph">
              We network with several government organizations to make sure that
              you are always current on changes to legislation.
            </li>
            <li className="paragraph">
              If you require training specific to your company, we can do this
              for you.
            </li>
            <li className="paragraph">
              Our customized training, our specialty, makes learning a
              worthwhile experience for all participants.
            </li>
          </ul>
        </div>
        <div className="didomi-safety__section-part-2">
          <div className="">
            <img src="img/didomi-24.jpg" alt="didomi-24" />
          </div>
        </div>
      </div>

      <div className="didomi-safety__section">
        <div className="didomi-safety__section-part-1">
          <h4 className="heading-tertiary-normal u-margin-bottom-small u-center-text">
            We See Health and Safety Differently
          </h4>
          <p className="paragraph u-margin-bottom-small">
            The purpose of health and safety is to protect you, your workers,
            sub-contractors, customers and members of the public when they are
            involved with your business.
          </p>
          <p className="paragraph">
            Workplace health and safety is important for the health and
            wellbeing of all employees across all industries because human
            injury or loss in any business is not acceptable. And the last thing
            you want is to be the employer who loses an employee in an accident
            and is then charged with corporate manslaughter.
          </p>
        </div>
        <div className="didomi-safety__section-part-2">
          <div className="">
            <img src="img/didomi-23.jpg" alt="didomi-23" />
          </div>
        </div>
      </div>

      <div className="didomi-safety__section">
        <div className="didomi-safety__section-part-1">
          <h4 className="heading-tertiary-normal u-margin-bottom-small u-center-text">
            Why Didomi?
          </h4>
          <ul className="u-margin-left-small">
            <li className="paragraph">We pay attention to every details</li>
            <li className="paragraph">Well trained specialist and staffs</li>
            <li className="paragraph">
              Every company is important, regardless of size, area and
              structure.
            </li>
            <li className="paragraph">Facilitator and trainer on staff</li>
            <li className="paragraph">
              Provide adequate training facilities and sessions
            </li>
            <li className="paragraph">
              We provide safety, environmental equipment sales and supplies
            </li>
          </ul>
        </div>
        <div className="didomi-safety__section-part-2">
          <div className="">
            <img src="img/didomi-26.jpg" alt="didomi-26" />
          </div>
        </div>
      </div>

      <div className="didomi-safety__section">
        <div className="didomi-safety__section-part-1">
          <h4 className="heading-tertiary-normal u-margin-bottom-small u-center-text">
            Benefits
          </h4>
          <ul className="u-margin-left-medium">
            <li className="paragraph">improved health and wellbeing</li>
            <li className="paragraph">greater productivity</li>
            <li className="paragraph">higher performance</li>
            <li className="paragraph">increased job satisfaction</li>
            <li className="paragraph">Avoid hazardous incident</li>
          </ul>
        </div>
        <div className="didomi-safety__section-part-2">
          <div className="">
            <img src="img/didomi-25.jpg" alt="didomi-25" />
          </div>
        </div>
      </div>

      {/* <div className="didomi-safety__section">
        <div className="didomi-safety__section-part-1 u-center-text">
          <div className="didomi-safety__section-img-box">
            <img src="img/didomi-17.jpg" alt="didomi-17" />
          </div>
          <h4 className="heading-tertiary-normal">Director's Name</h4>
          <h5 className="heading-tertiary-normal-white">
            The Managing Director
          </h5>
        </div>
      </div> */}

      <div className="didomi-safety__section">
        <div className="didomi-safety__section-part-1 u-center-text u-margin-bottom-medium">
          <h4 className="heading-tertiary-normal">
            <a
              as={Link}
              href="/contact-us"
              className="didomi-safety__section-link"
            >
              Contact us today
            </a>{" "}
          </h4>
          <p className="didomi-safety__contact-para">
            <i className="fa-solid fa-phone"></i> +234 807 116 7839 | +234
            9156377772
          </p>
          <p className="didomi-safety__contact-para">
            Info@didomiconsortium.com
          </p>
        </div>
      </div>
    </div>
  );
};

export default DidomiSafety;
