import React from "react";
import { Helmet } from "react-helmet";

const Meta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
};

Meta.defaultProps = {
  title: "Didomi Company Limited",
  description:
    "Didomi Company Limited is one of the leading global education provider that delivers an extensive range of educational services to many students in Nigeria and all the African communities annually to our partner institutions in the UK, USA, CANADA and AUSTRALIA.",
  keywords:
    "DidomiCompanyLimited, didomi, Didomi, didomiconsortium, didomiconsortium.com",
};

export default Meta;
