import React, { useState, useRef, useEffect } from "react";

const SectionThree = () => {
  const sectRef = useRef(null);
  const [inView, setInView] = useState(null);

  function handleScroll() {
    // console.log("scrolling");

    const bounding = sectRef.current.getBoundingClientRect();
    // console.log(bounding);

    // Detect when element is visible in view port (partially)
    if (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.top <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <=
        (window.innerWidth || document.documentElement.clientWidth)
    ) {
      //   console.log("Element is in the viewport!");
      setInView("show-tech-sect-3");
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className={`tech-sect-3 ${inView}`} ref={sectRef}>
      <div className="tech-sect-3__div-1">
        <h4 className="tech-sect-3__text-sub">DIDOMI TECHNOLOGY</h4>
        <h2 className="tech-sect-3__text-main heading-secondary-white">
          We are a web design and development company, building websites that
          drive traffic, engagement, and conversion for industry-leading brands
          and startups
        </h2>
      </div>
      <div className="tech-sect-3__div-2">
        <img src="img/didomi-logo.png" alt="didomi logo" />
      </div>
    </div>
  );
};

export default SectionThree;
