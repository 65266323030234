import http from "./httpService";
import { getJwt } from "./authService";

const apiEndpoint = "/email";

const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${getJwt()}`,
  },
};

export function sendEmail(mail) {
  return http.post(
    apiEndpoint,
    {
      to: mail.emailList,
      subject: mail.subject,
      text: mail.text,
    },
    config
  );
}

export function receiveEmail(mail) {
  return http.post(
    `${apiEndpoint}/receive-email`,
    {
      name: mail.name,
      email: mail.email,
      subject: mail.subject,
      message: mail.message,
    },
    config
  );
}
