import React, { useRef, useEffect } from "react";

const Jcarousel = () => {
  const item_1 = useRef();
  const item_2 = useRef();
  const item_3 = useRef();

  const carouselItems = [item_1, item_2, item_3];

  let intervalId = 0;
  let count = 0;

  function playCarousel() {
    for (let i = 0; i < carouselItems.length; i++) {
      if (count === i) {
        carouselItems[i].current.style.opacity = "1";
        carouselItems[i].current.style.transform = "scale(1)";
        continue;
      }
      carouselItems[i].current.style.transform = "scale(0)";
      carouselItems[i].current.style.opacity = "0";
    }

    count = count + 1;
    count = count === 3 ? 0 : count;
  }

  intervalId = setInterval(playCarousel, 6000);

  useEffect(() => {
    return () => clearInterval(intervalId);
  }, [intervalId]);

  return (
    <div className="j-carousel__wrapper" id="top">
      <div ref={item_1} className="j-carousel__item j-carousel__item-1">
        <h1 className="heading-primary">
          <span className="heading-primary__main">Didomi Travel Agency</span>
          <span className="heading-primary__sub">
            Your Study Abroad Solution
          </span>
        </h1>
      </div>
      <div ref={item_2} className="j-carousel__item j-carousel__item-2">
        <h1 className="heading-primary">
          <span className="heading-primary__main">
            Didomi Safety Consultancy
          </span>
          <span className="heading-primary__sub">
            Never Give Safety a Day Off
          </span>
        </h1>
      </div>
      <div ref={item_3} className="j-carousel__item j-carousel__item-3">
        <h1 className="heading-primary">
          <span className="heading-primary__main">Didomi Technologies</span>
          <span className="heading-primary__sub">
            We Prioritize Web Solution
          </span>
        </h1>
      </div>
    </div>
  );
};

export default Jcarousel;
