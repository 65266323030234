import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Dropdown = ({ objectList }) => {
  const [itemsList, setItemsList] = useState([]);

  const handleClick = (index) => {
    const newItemsList = [];

    itemsList.forEach((item, i) => {
      if (index === i) {
        // if it was true before, make it false. i.e when clicked again, toggle it
        if (item["showDropdown"] === true) item["showDropdown"] = false;
        else item["showDropdown"] = true;
      } else item["showDropdown"] = false;

      newItemsList.push(item);
    });

    setItemsList(newItemsList);
  };

  useEffect(() => {
    setItemsList(objectList);
  }, [objectList]);

  return (
    <div className="dropdown u-margin-top-medium u-margin-bottom-medium">
      {itemsList.map((item, index) => (
        <div className="dropdown__main-sub" key={index}>
          <div
            className={`dropdown__main ${
              item.showDropdown ? "dropdown__main-active" : null
            }`}
            onClick={() => handleClick(index)}
          >
            {item.main}
          </div>
          <div
            className={`dropdown__sub ${
              item.showDropdown ? "dropdown__show-sub" : null
            }`}
          >
            {typeof item.sub === "string" ? (
              item.sub
            ) : (
              <ul className="dropdown__sub-list">
                {item.sub.map((university, i) => (
                  <li key={i + 2}>
                    <a
                      as={Link}
                      href={university.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {university.name}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Dropdown;
