import React from "react";
import { Link } from "react-router-dom";
import HomeCarousel from "./common/HomeCarousel";

const SectionOne = () => {
  return (
    <section className="section-1">
      <div className="section-1__text-box">
        <div className="u-margin-bottom-small">
          <h2 className="heading-secondary-medium">What we do</h2>
        </div>

        <p className="paragraph">
          Didomi company limited is one of the leading international education
          providers, we deliver extensive range of educational services to many
          students in Nigeria and all other countries. Our success comes from
          connecting students with the right university or institution and the
          right country. Our experts help you identify and secure the university
          or college where you can thrive.
        </p>
        <div className="">
          <a className="btn" as={Link} href="/didomi-travel">
            Learn more &rarr;
          </a>
        </div>
        <p className="paragraph u-margin-top-small">
          Also, Didomi company limited is a service-oriented consulting firm
          which for some years have been helping companies in ALL sectors go
          from `low’ safety to `complete’ safety in all spheres of health and
          safety.
        </p>
        <div className="u-margin-bottom-medium">
          <a className="btn" as={Link} href="/didomi-safety">
            Learn more &rarr;
          </a>
        </div>
      </div>
      <div className="section-1__img-box">
        <HomeCarousel />
      </div>
    </section>
  );
};

export default SectionOne;
