import React from "react";
import Meta from "./common/Meta";

const About = () => {
  return (
    <div className="about-us">
      <Meta title={"About Us | Didomi Company Limited"} />
      <div className="about-us__header">
        <div className="about-us__header-text">
          <h1 className="about-us__header-text-main">ABOUT DIDOMI</h1>
          <p className="about-us__header-text-sub">
            A wide range of service delivery to students in Nigeria and all
            other countries. Helping companies grow from low safety technique to
            complete safety.
          </p>
        </div>
      </div>

      <div className="about-us__heading-sub">
        <h2 className="heading-secondary">About Didomi</h2>
      </div>
      <div className="about-us__heading-border"></div>
      <p className="paragraph about-us__paragraph">
        Didomi company limited is one of the leading international education
        providers, we deliver extensive range of educational services to many
        students in Nigeria and all other countries. Our experts help you
        identify and secure the university or college where you can thrive. Our
        safety sector provide companies with a complete safety first technique
        at offices and industries.
      </p>

      <div className="card">
        <div className="card__side card__side--front">
          <h4 className="card__heading">
            <span className="card__heading-span">Our Mission</span>
          </h4>

          <div className="card__details">
            <ul>
              <li>
                To provide outstanding educational assistance to international
                students and across the African communities.
              </li>
              <li>
                To fulfill career ambitions through study abroad, and seeking
                for opportunities overseas for study and job placement for a
                better life and a secured future.
              </li>
              <li>
                To create proactive awareness and promote environmental, food
                safety and health awareness worldwide.
              </li>
            </ul>
          </div>
        </div>
        <div className="card__side card__side--back">
          <h4 className="card__heading">
            <span className="card__heading-span">Our Mission</span>
          </h4>

          <div className="card__details">
            <ul>
              <li>
                To provide outstanding educational assistance to international
                students and across the African communities.
              </li>
              <li>
                To fulfill career ambitions through study abroad, and seeking
                for opportunities overseas for study and job placement for a
                better life and a secured future.
              </li>
              <li>
                To create proactive awareness and promote environmental, food
                safety and health awareness worldwide.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card__side card__side--front">
          <h4 className="card__heading">
            <span className="card__heading-span">Our Vision</span>
          </h4>

          <div className="card__details">
            <ul>
              <li>
                We aim to provide good service for vital, comprehensive, and
                good information, advice and guidance on any aspect of student
                recruitment and admissions to our prospect clients
              </li>
              <li>
                Building one of the world’s most successful travel companies.
              </li>
              <li>
                To make environmental, food safety and health management an
                integral part of every sector in all organization worldwide.
              </li>
            </ul>
          </div>
        </div>
        <div className="card__side card__side--back">
          <h4 className="card__heading">
            <span className="card__heading-span">Our Vision</span>
          </h4>

          <div className="card__details">
            <ul>
              <li>
                We aim to provide good service for vital, comprehensive, and
                good information, advice and guidance on any aspect of student
                recruitment and admissions to our prospect clients
              </li>
              <li>
                Building one of the world’s most successful travel companies.
              </li>
              <li>
                To make environmental, food safety and health management an
                integral part of every sector in all organization worldwide.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card__side card__side--front">
          <h4 className="card__heading">
            <span className="card__heading-span">Our Focus</span>
          </h4>

          <div className="card__details">
            <ul>
              <li>
                Guiding prospective students towards course choosing and
                education opportunities at universities or colleges around the
                world.
              </li>
              <li>
                To be helpful, supportive, dependable and trustworthy
                educational provider.
              </li>
              <li>
                Our focus is to promote development and professional best
                practices on environmental, food safety and health service
                delivery in the industry and organizations as well as to
                contribute and express its views on all related regulations,
                policies, and legislation on environmental, food safety and
                health in any region.
              </li>
            </ul>
          </div>
        </div>
        <div className="card__side card__side--back">
          <h4 className="card__heading">
            <span className="card__heading-span">Our Focus</span>
          </h4>

          <div className="card__details">
            <ul>
              <li>
                Guiding prospective students towards course choosing and
                education opportunities at universities or colleges around the
                world.
              </li>
              <li>
                To be helpful, supportive, dependable and trustworthy
                educational provider.
              </li>
              <li>
                Our focus is to promote development and professional best
                practices on environmental, food safety and health service
                delivery in the industry and organizations as well as to
                contribute and express its views on all related regulations,
                policies, and legislation on environmental, food safety and
                health in any region.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
