import React from "react";

const SectionTwo = () => {
  return (
    <div className="tech-sect-2">
      <div className="tech-sect-2__div-1">
        <h4 className="tech-sect-2__text-sub">WHO WE ARE</h4>
        <h2 className="tech-sect-2__text-main heading-secondary-black">
          From product thinking to post production process, we have a passionate
          team rooted in web technology.
        </h2>
        <span className="tech-sect-2__btn">
          <button className="j-btn">
            <a
              href="https://wa.me/message/4PWF5RH2M5TUA1"
              target="_blank"
              rel="noreferrer"
            >
              GET FREE CONSULTATION
            </a>{" "}
          </button>
        </span>
        <span className="tech-sect-2__btn">
          <button className="j-btn">
            <a href="tel:+2348137192766">CALL US ON +2348137192766</a>
          </button>
        </span>
      </div>
      <div className="tech-sect-2__div-2">
        <img src="img/didomi-logo.png" alt="didomi logo" />
      </div>
    </div>
  );
};

export default SectionTwo;
