import React from "react";
import Meta from "./common/Meta";
import SectionOne from "./SectionOne";
import SectionTwo from "./SectionTwo";
import SectionThree from "./SectionThree";
import SectionFive from "./SectionFive";
import Jcarousel from "./common/Jcarousel";

const Home = () => {
  return (
    <div className="home">
      <Meta title={"Didomi Company Limited"} />
      <Jcarousel />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFive />
    </div>
  );
};

export default Home;
