import React, { useEffect, useState } from "react";
import { resetPassword } from "../services/userService";
import Meta from "./common/Meta";
import auth from "../services/authService";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("reset");

    try {
      const { data } = await resetPassword(email);
      // console.log(data);
      alert(data.message);
      window.location.pathname = "/";
    } catch (ex) {
      setErrorMessage(ex.response.data);
      // console.log(ex);
    }
  };

  useEffect(() => {
    if (auth.getCurrentUser()) navigate("/");
  }, [navigate]);

  return (
    <div className="reset-password">
      <Meta title={"Reset password | Didomi Company Limited"} />
      <div className="reset-password__form-container">
        <form
          className="form-content"
          onSubmit={(e) => handleSubmit(e)}
          noValidate
        >
          <input
            type="email"
            value={email}
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email address"
          />
          <br />
          <p className="form-content__error-message">{errorMessage}</p>
          <button type="submit">Reset Password</button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
