import React from "react";
import Meta from "./common/Meta";
import Dropdown from "./common/Dropdown";
import { faq } from "../services/faqService";

const Scholarship = () => {
  return (
    <section className="scholarship">
      <Meta title={"Scholarship | Didomi Company Limited"} />{" "}
      <div className="scholarship__header">
        <div className="scholarship__heading-main">
          <h2>Scholarship</h2>
        </div>
        <div className="scholarship__heading-sub">
          <p>
            Didomi educational consultancy assist students in accessing a
            variety of scholarships designed to help you and your family pay for
            university. Scholarships and grants are types of gifts or aid that
            do not have to be repaid. They may take the form of University
            scholarships, federal or state grants, or outside scholarships.
          </p>
        </div>
      </div>
      <div className="scholarship__packages-main-header">
        <i className="fa-solid fa-graduation-cap"></i>
        <h2 className="heading-secondary-medium">Scholarship Packages</h2>
      </div>
      <div className="scholarship__packages">
        <div className="scholarship__package">
          <h3 className="heading-tertiary-normal">Scholarships in Nigeria</h3>
          <p className="paragraph">
            We also provide advice to student on how to secure local
            Scholarships in Nigeria
          </p>
        </div>
        <div className="scholarship__package">
          <h3 className="heading-tertiary-normal">Postgraduate</h3>
          <p className="paragraph">
            Automatic twenty percent (20%) scholarship for postgraduate courses
            in environmental sciences
          </p>
        </div>
        <div className="scholarship__package">
          <h3 className="heading-tertiary-normal">Nigerians and Africans</h3>
          <p className="paragraph">
            Automatic ten percent (10%) scholarship is given to all Nigerian and
            African students from the universities
          </p>
        </div>
      </div>
      <div className="scholarship__details">
        <div className="scholarship__details-text">
          <div className="u-margin-bottom-small">
            <h3 className="heading-tertiary-normal">Requirements</h3>
          </div>

          <p className="paragraph-primary u-margin-bottom-medium">
            With this in mind, we have new academic scholarship opportunities
            that complement our long standing tradition of scholarship
            assistance for all students pursuing further studies. Applicants
            must meet the requirements below{" "}
          </p>
          <ul className="scholarship__details-class paragraph u-margin-bottom-medium">
            <li>Masters going for PhD</li>
            <li>Degree going for Masters</li>
            <li>SSCE / WASSCE going for undergraduate degree</li>
            <li className="u-margin-bottom-medium">
              Available for all Nigerians
            </li>
          </ul>
          <div className="u-margin-bottom-small">
            <h3 className="heading-tertiary-normal">Guidance and Advice</h3>
          </div>

          <p className="paragraph">
            When applying for scholarships, effective time management is crucial
            especially when you have multiple applications to complete. Using a
            calendar or any planner of your choice, you can set schedules for
            gathering documents you will need for your scholarship application
            process. Preparing well ahead of time gives you enough time to
            understand the scholarship requirements, get your reference letters
            ready, craft an impressive essay and review your application
            carefully before submitting it.
          </p>
        </div>
        <div className="scholarship__details-img-box">
          <div className="scholarship__details-img-box-2">
            <div className="scholarship__details-img-circle">
              {/* <img src="img/didomi-17.jpg" alt="didomi-17" /> */}
              <i className="bi bi-person-circle"></i>
            </div>
            <h3 className="heading-tertiary-normal u-margin-top-medium u-margin-bottom-small">
              Featured Student (Scholarship)
            </h3>
            <p className="paragraph u-margin-bottom-small">
              ”I am sincerely honored to have been selected as a recipient of
              the Lietuviu Fondo Stipendija. Your generosity has brought me one
              step closer to achieving my goal. I am a biochemistry major and I
              plan to pursue a career in the medical field upon graduation from
              Merrimack College. Your generosity has inspired me to help others
              and to give back to the community. I hope one day I will be able
              to help students achieve their goal just as you have helped me.”
            </p>

            <h3 className="heading-tertiary-normal u-margin-bottom-small">
              John Smith
            </h3>
            <p className="paragraph">
              2020 LF scholarship recipient – Merrimack College, MA
            </p>
          </div>
        </div>
      </div>
      <div className="scholarship__actions">
        <div className="scholarship__enquiry">
          <div className="u-margin-bottom-small">
            <h3 className="heading-tertiary-normal">Make Enquiry</h3>
          </div>
          <p className="paragraph">
            We are open on Monday – Friday at 8am to 5pm, except on holidays.
          </p>
          <div className="u-margin-bottom-small u-margin-top-small">
            <h4>Didomi Travel</h4>
          </div>
          <address className="scholarship__contact-info">
            6, Soji Adepegba close Allen Avenue, <br />
            Ikeja Lagos Nigeria. <br />
            <a href="tel:+2348071167839">807 116 7839</a>
            <br />
            <a href="tel:+2349156377772">915 637 7772</a>
            <br />
            <a href="mailto: info@didomiconsortium.com">
              info@didomiconsortium.com
            </a>
          </address>
        </div>
        <div className="scholarship__faq">
          <div className="u-margin-bottom-small">
            <h3 className="heading-tertiary-normal">
              Frequently Asked Questions
            </h3>
          </div>
          <p className="paragraph">
            At GEC we understand that education is one of the best decisions to
            make. As such we have put together a list of Frequently Asked
            Questions (FAQ) that will guide you in your decision making. If you
            need any further information on any of these questions please feel
            free to get in touch with us.
          </p>
          <Dropdown objectList={faq} />
        </div>
      </div>
    </section>
  );
};

export default Scholarship;
