import React, { useEffect, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Dropdown from "./common/Dropdown";
import { getUniversities } from "../services/universityService";
import { getStatistics } from "../services/statsService";

const Institutions = () => {
  const [institutions, setInstitutions] = useState([]);
  const [stats, setStats] = useState({});

  const targetRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();

  const target = searchParams.get("section");

  const scrollToTarget = () => {
    setTimeout(() => {
      targetRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }, 500);
  };

  const getInstitutions = async () => {
    try {
      const { data } = await getUniversities();
      setInstitutions(data);
    } catch (ex) {
      // console.log(ex);
    }
  };

  const getStats = async () => {
    try {
      const { data } = await getStatistics();
      setStats(data);
    } catch (ex) {
      // console.log(ex);
    }
  };

  useEffect(() => {
    getInstitutions();
    getStats();

    if (target === "institution") {
      // console.log("Yeah.");
      scrollToTarget();
    }
  }, [target]);

  return (
    <section>
      <div className="part-institution">
        <div className="part-institution__container">
          <div className="part-institution__text">
            <div className="u-center-text u-margin-bottom-small">
              <h2 className="heading-secondary-white" ref={targetRef}>
                Didomi Partner Universities
              </h2>
            </div>
            <p className="paragraph-white u-center-text">
              Didomi Travels collaborate with many universities abroad
            </p>
          </div>
          <div className="part-institution__dropdown">
            <Dropdown objectList={institutions} />
          </div>
        </div>
      </div>
      <div className="applications">
        <div className="applications__rating">
          <AnimationOnScroll animateIn="animate__bounceInLeft">
            <div className="applications__rating-value">
              {stats.applicants}+
            </div>
            <div className="applications__rating-text">All Time Applicants</div>
          </AnimationOnScroll>
        </div>
        <div className="applications__rating">
          <div className="applications__rating-value">
            {stats.availableUniversities}+
          </div>
          <div className="applications__rating-text">
            Available Universities
          </div>
        </div>
        <div className="applications__rating">
          <div className="applications__rating-value">{stats.alumni}</div>
          <div className="applications__rating-text">Alumni</div>
        </div>
        <div className="applications__rating">
          <AnimationOnScroll animateIn="animate__bounceInRight">
            <div className="applications__rating-value">
              {stats.successRate}%
            </div>
            <div className="applications__rating-text">Success rate</div>
          </AnimationOnScroll>
        </div>
      </div>
    </section>
  );
};

export default Institutions;
