import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Joi from "joi-browser";
import { getUserById, adminUpdateUser } from "../../services/userService";

const EditApplicant = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [level, setLevel] = useState("");
  const [isAdmin, setIsAdmin] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const schema = {
    firstName: Joi.string().required().label("First name"),
    lastName: Joi.string().required().label("Last name"),
    email: Joi.string().required().email().label("Email"),
    phone: Joi.number().required().label("Phone"),
    country: Joi.string().required().label("Country"),
    level: Joi.string().required().label("Level"),
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await getUserById(id);
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setEmail(data.email);
        setPhone(data.phone);
        setCountry(data.country);
        setLevel(data.level);
        setIsAdmin(data.isAdmin);
        console.log(data);
      } catch (ex) {
        // console.log(ex);
      }
    };

    getData();
  }, [id]);

  const updateData = async (userDetails) => {
    try {
      await adminUpdateUser(userDetails, id);
      navigate(`/dashboard/applicant/${id}`); // go to applicant profile
    } catch (ex) {
      setErrorMessage(ex.response.data.message);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      firstName,
      lastName,
      email,
      phone,
      country,
      level,
    };

    const { error } = Joi.validate(data, schema);

    data.isAdmin = isAdmin;

    if (error) {
      // console.log(error.details[0].path[0]);
      setErrorMessage(error.details[0].message);
    } else {
      setErrorMessage("");
      if (window.confirm("Press ok to continue")) {
        updateData(data);
      }
    }
  };

  return (
    <div className="">
      <h3 className="dashboard-main__header">Manage Applicant</h3>
      <div className="dashboard-main">
        <div className="dashboard-main__1">
          <form
            className="form-content"
            onSubmit={(e) => handleSubmit(e)}
            noValidate
          >
            <input
              type="text"
              value={firstName}
              name="firstName"
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="First name"
            />
            <br />
            <input
              type="text"
              value={lastName}
              name="lastName"
              onChange={(e) => setLastName(e.target.value)}
              placeholder="Last name"
            />
            <br />
            <input
              type="email"
              value={email}
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email address"
            />
            <br />
            <input
              type="phone"
              value={phone}
              name="phone"
              onChange={(e) => setPhone(e.target.value)}
              placeholder="Phone"
            />
            <br />
            <select
              name="institution"
              className="form-content__select"
              onChange={(e) => setCountry(e.target.value)}
              defaultValue=""
            >
              <option value="" disabled hidden>
                Choose Country...
              </option>
              <option value="United Kingdom">UK</option>
              <option value="Canada">Canada</option>
              <option value="United State of America">USA</option>
              <option value="Germany">Germany</option>
              <option value="Australia">Australia</option>
            </select>
            <br />
            <select
              name="institution"
              className="form-content__select"
              onChange={(e) => setLevel(e.target.value)}
              defaultValue=""
            >
              <option value="" disabled hidden>
                Choose Level...
              </option>
              <option value="English Language School">
                English Language School
              </option>
              <option value="Undergraduate">Undergraduate</option>
              <option value="Postgraduate">Postgraduate</option>
              <option value="Doctorate">Doctorate</option>
            </select>
            <select
              name="admin"
              className="form-content__select"
              onChange={(e) => setIsAdmin(e.target.value)}
              defaultValue=""
            >
              <option value="" disabled hidden>
                Make applicant admin...
              </option>
              <option value={true}>Approve</option>
              <option value={false}>Disapprove</option>
            </select>
            <p className="form-content__error-message"> {errorMessage} </p>{" "}
            <button type="submit"> Update </button>{" "}
          </form>{" "}
        </div>
      </div>
    </div>
  );
};

export default EditApplicant;
