import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { searchUniversities } from "../services/universityService";
import SearchItems from "./common/SearchItems";
import Meta from "./common/Meta";

const SearchResult = () => {
  const [searchResult, setSearchResult] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const keyword = searchParams.get("institution");

  //   console.log(keyword);

  const getUni = async (keyword) => {
    try {
      const { data } = await searchUniversities(keyword);
      //   console.log(data);
      setSearchResult(data);
    } catch (ex) {
      console.log(ex);
    }
  };

  useEffect(() => {
    getUni(keyword);
  }, [keyword]);

  return (
    <div className="search-result">
      <Meta title={"Search result | Didomi Company Limited"} />
      <div className="search-result__header">
        <div className="search-result__header-text">
          <h1 className="search-result__header-text-main">SEARCH RESULT</h1>
          <p className="search-result__header-text-sub">{keyword}</p>
        </div>
      </div>
      <div className="search-result__content">
        <h2 className="heading-tertiary u-margin-bottom-large">Institutions</h2>
        <SearchItems result={searchResult} keyword={keyword} />
      </div>
    </div>
  );
};

export default SearchResult;
