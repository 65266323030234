import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { getUsers, deleteUser } from "../../services/userService";

const Applicants = () => {
  let [loading, setLoading] = useState(true);

  const [users, setUsers] = useState([]);
  const [page, setPage] = useState({
    currentPage: 1,
    totalPage: 1,
  });
  const [serial, setSerial] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await getUsers(page.currentPage);
        // console.log(data);
        setPage({
          currentPage: data.page,
          totalPage: data.pages,
        });
        setSerial(data.serialNumbers);
        setUsers(data.users);
        setLoading(false);
      } catch (ex) {
        // console.log(ex);
        setLoading(false);
      }
    };

    getData();
  }, [page.currentPage]);

  const userDelete = async (id) => {
    try {
      await deleteUser(id);
      window.location = "/dashboard/applicants";
    } catch (ex) {
      // console.log(ex);
    }
  };

  const handleDelete = (id) => {
    // console.log("clicked");
    if (window.confirm("Press Ok to Proceed")) {
      userDelete(id);
    }
  };

  const handlePrev = () => {
    // console.log("Prev clicked");
    if (page.currentPage > 1) {
      let currentPage = page.currentPage - 1;
      setPage({
        currentPage: currentPage,
        totalPage: page.totalPage,
      });
    }

    // console.log(page.currentPage);
  };

  const handleNext = () => {
    // console.log("Next clicked");
    if (page.currentPage < page.totalPage) {
      let currentPage = page.currentPage + 1;
      setPage({
        currentPage: currentPage,
        totalPage: page.totalPage,
      });
    }

    // console.log(page.currentPage);
  };
  return (
    <>
      <h3 className="dashboard-main__header">Applicants</h3>
      <ClipLoader color="#54aa01" loading={loading} />
      <div className="dashboard-main">
        <table className="user-table">
          <thead>
            <tr>
              <th>SN</th>
              <th>Name</th>
              <th className="dis-none-phone">Phone</th>
              <th className="dis-none-phone">Country of Study</th>
              <th className="dis-none-phone">Study Level</th>
              <th className="dis-none-phone">Applied</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {users.map((item, index) => (
              <tr key={item._id}>
                <td>{serial[index]}</td>
                <td>
                  {" "}
                  <Link to={`/dashboard/applicant/${item._id}`}>
                    {`${item.firstName} ${item.lastName}`}
                  </Link>
                </td>
                <td className="dis-none-phone">{item.phone}</td>
                <td className="dis-none-phone">{item.country}</td>
                <td className="dis-none-phone">{item.level}</td>
                <td className="dis-none-phone">
                  {item.createdAt.substring(0, 10)}
                </td>
                <td>
                  <Link to={`/dashboard/applicant/${item._id}/edit`}>
                    Edit <i className="bi bi-pencil-square"></i>
                  </Link>
                </td>
                <td>
                  <span onClick={() => handleDelete(item._id)}>
                    Delete <i className="bi bi-trash3"></i>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="dashboard-pagin">
        <button
          className="dashboard-pagin__button"
          onClick={() => handlePrev()}
        >
          Prev
        </button>
        <button
          className="dashboard-pagin__button"
          onClick={() => handleNext()}
        >
          Next
        </button>
        <span className="dashboard-pagin__number">
          Page {page.currentPage} of {page.totalPage}
        </span>
      </div>
    </>
  );
};

export default Applicants;
