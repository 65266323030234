import React, { useState, useRef } from "react";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import ClipLoader from "react-spinners/ClipLoader";
import { receiveEmail } from "../../services/mailService";

const ContactForm = () => {
  let [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const schema = {
    name: Joi.string().required().label("Full name"),
    email: Joi.string().required().email().label("Email"),
    subject: Joi.string().required().label("Subject"),
    message: Joi.string().required().label("Message"),
  };

  const messageSender = async (mail) => {
    try {
      const { data } = await receiveEmail(mail);
      setLoading(false);
      toast(`${data.message}`, { className: "toast-style" });
    } catch (ex) {
      setLoading(false);
      toast(ex.response.data.message, { className: "toast-style" });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setErrorMessage("");

    // console.log("submitted");

    const obj = {
      name,
      email,
      subject,
      message,
    };

    // console.log(formRef.current);

    const { error } = Joi.validate(obj, schema);

    if (error) {
      setErrorMessage(error.details[0].message);
    } else {
      setLoading(true);
      setErrorMessage("");
      messageSender(obj);
    }
  };

  return (
    <form className="page-contact-us__form" onSubmit={(e) => handleSubmit(e)}>
      <div className="page-contact-us__form-row">
        <label htmlFor="name">
          Full name <span>*</span>
        </label>
        <input
          type="text"
          value={name}
          name="name"
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="page-contact-us__form-row">
        <label htmlFor="email">
          Email <span>*</span>
        </label>
        <input
          type="email"
          value={email}
          name="email"
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="page-contact-us__form-row">
        <label htmlFor="subject">
          Subject <span>*</span>
        </label>
        <input
          type="text"
          value={subject}
          name="subject"
          onChange={(e) => setSubject(e.target.value)}
        />
      </div>
      <div className="page-contact-us__form-row">
        <label htmlFor="message">
          Message <span>*</span>
        </label>
        <textarea
          rows={5}
          value={message}
          name="message"
          onChange={(e) => setMessage(e.target.value)}
        />
      </div>
      <ClipLoader color="#54aa01" loading={loading} />
      <div className="page-contact-us__form-row">
        <p>{errorMessage}</p>
        <button type="submit">Submit</button>
      </div>
    </form>
  );
};

export default ContactForm;
