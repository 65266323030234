import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";

const Header = ({
  handleShowSidebar: setSidebar,
  handleShowSearchPopup: setSearchPopup,
}) => {
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    // console.log(e.target.search.value);
    const keyword = e.target.search.value;

    navigate(`/search?institution=${keyword}`);
  };
  return (
    <div className="header">
      <Link to="/">
        <img
          src="/img/didomi-logo.png"
          alt="Didomi Consortium"
          className="logo"
        />
      </Link>
      <form className="search" onSubmit={(e) => handleSubmit(e)}>
        <input
          type="text"
          className="search__input"
          name="search"
          placeholder="Search schools"
        />
        <button className="search__button" type="submit">
          <i className="fa-solid fa-magnifying-glass search__icon"></i>
        </button>
      </form>

      <div className="search-icon">
        <i className="bi bi-search" onClick={() => setSearchPopup()}></i>
      </div>

      <div className="header__nav-items">
        <NavLink
          to="/"
          className={({ isActive }) =>
            isActive ? "header__link-active" : "header__link"
          }
        >
          Home
        </NavLink>
        <NavLink
          to="/about-us"
          className={({ isActive }) =>
            isActive ? "header__link-active" : "header__link"
          }
        >
          About
        </NavLink>
        <NavLink
          to="/didomi-safety"
          className={({ isActive }) =>
            isActive ? "header__link-active" : "header__link"
          }
        >
          Safety
        </NavLink>
        <NavLink
          to="/didomi-travel"
          className={({ isActive }) =>
            isActive ? "header__link-active" : "header__link"
          }
        >
          Travel
        </NavLink>
        <NavLink
          to="/scholarship"
          className={({ isActive }) =>
            isActive ? "header__link-active" : "header__link"
          }
        >
          Scholarship
        </NavLink>
        <NavLink
          to="/technology"
          className={({ isActive }) =>
            isActive ? "header__link-active" : "header__link"
          }
        >
          Technology
        </NavLink>
        <NavLink
          to="/contact-us"
          className={({ isActive }) =>
            isActive ? "header__link-active" : "header__link"
          }
        >
          Contact-us
        </NavLink>
      </div>

      <div className="header__search-and-sidebarButton">
        <div className="search-icon-tab-port">
          <i className="bi bi-search" onClick={() => setSearchPopup()}></i>
        </div>
        <nav className="header__nav">
          <button
            className="sidebar-button"
            onClick={() => {
              setSidebar();
            }}
          >
            <i className="fa-solid fa-bars nav-icon sidebar-button__icon"></i>
          </button>
        </nav>
      </div>
    </div>
  );
};

export default Header;
