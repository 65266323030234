import React from "react";
import { NavLink, Outlet } from "react-router-dom";

const UserDashboard = () => {
  return (
    <div className="dashboard">
      <div className="dashboard__header">
        <h2 className="heading-secondary">Dashboard</h2>
      </div>
      <div className="dashboard__container">
        <div className="dashboard__container-1">
          <nav className="dashboard-nav">
            <div className="dashboard-nav__item">
              <NavLink to="/dashboard">
                <div>Profile</div>
              </NavLink>
            </div>
            <div className="dashboard-nav__item">
              <NavLink to="/dashboard/edit-profile">
                <div>Edit Profile</div>
              </NavLink>
            </div>
          </nav>
        </div>
        <div className="dashboard__container-2">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default UserDashboard;
