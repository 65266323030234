import React, { useState, useRef, useEffect } from "react";

const SectionFour = () => {
  const div_1Ref = useRef(null);

  const [inView, setInView] = useState(null);

  function handleScroll() {
    const bounding = div_1Ref.current.getBoundingClientRect();
    const windowHeight =
      window.innerHeight || document.documentElement.clientHeight;

    const showOnHeight = windowHeight / 5;

    // console.log(showOnHeight);
    // console.log(windowHeight);

    // console.log("bounding 1, top:", bounding.top);
    // console.log("bounding 1, bottom:", bounding.bottom);

    // Detect when element is fully visible in view port
    if (
      bounding.left >= 0 &&
      bounding.top <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <=
        (window.innerWidth || document.documentElement.clientWidth)
    ) {
      // console.log("Element is in the viewport!");

      if (showOnHeight >= bounding.top && windowHeight < bounding.bottom) {
        setInView("show-div-1");
      } else if (
        showOnHeight <= bounding.top &&
        windowHeight < bounding.bottom
      ) {
        setInView("hide-div-1");
      } else if (windowHeight > bounding.bottom) {
        setInView("hide-div-1");
      }
    } else {
      // console.log("Element is NOT in the viewport!");
      setInView("hide-div-1");
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`tech-sect-4`} ref={div_1Ref}>
      <div className={`tech-sect-4__div-1-imaginary`}>
        <h4 className="tech-sect-4__text-sub">OUR SERVICES</h4>
        <h2 className="tech-sect-4__text-main heading-secondary-black">
          Making each of our clients happy, one project at a time.
        </h2>
      </div>
      <div className={`tech-sect-4__div-1 ${inView}`}>
        <h4 className="tech-sect-4__text-sub">OUR SERVICES</h4>
        <h2 className="tech-sect-4__text-main heading-secondary-black">
          Making each of our clients happy, one project at a time.
        </h2>
      </div>

      <div className="tech-sect-4__div-2">
        <h2 className="heading-tertiary-alt u-margin-bottom-small">
          Website Design
        </h2>
        <p className="tech-sect-4__paragraph u-margin-bottom-medium">
          Our dedicated team of graphic designers and digital designers hone in
          on your brand vision to develop an impactful visual language for your
          online presence.
        </p>
        <h2 className="heading-tertiary-alt u-margin-bottom-small">
          Website Development
        </h2>
        <p className="tech-sect-4__paragraph u-margin-bottom-medium">
          From custom front-end to sophisticated back-end, our in-house
          development team handles the heavy lifting to create an engaging
          experience online.
        </p>
        <h2 className="heading-tertiary-alt u-margin-bottom-small">
          Responsive Web Design
        </h2>
        <p className="tech-sect-4__paragraph u-margin-bottom-medium">
          Never lose a customer that is too busy to view your website on a
          computer with a fully responsive experience across all devices.
        </p>
        <h2 className="heading-tertiary-alt u-margin-bottom-small">
          UI/UX Design
        </h2>
        <p className="tech-sect-4__paragraph u-margin-bottom-medium">
          We pride ourselves on pushing the boundaries of digital design and
          development. We combine relevant trends and best practices to build
          platforms with longevity.
        </p>
        <h2 className="heading-tertiary-alt u-margin-bottom-small">
          Content Management
        </h2>
        <p className="tech-sect-4__paragraph u-margin-bottom-medium">
          Achieve complete control of your website message with an intuitive
          content management system.
        </p>
        <h2 className="heading-tertiary-alt u-margin-bottom-small">
          Research and Discovery
        </h2>
        <p className="tech-sect-4__paragraph u-margin-bottom-medium">
          Each project is design-driven, but strategy led. We conduct a thorough
          research phase to ensure an effective design approach.
        </p>
        <h2 className="heading-tertiary-alt u-margin-bottom-small">
          Presentations
        </h2>
        <p className="tech-sect-4__paragraph u-margin-bottom-medium">
          We design polished presentations that add value to your speech, on and
          off the screen.
        </p>
        <h2 className="heading-tertiary-alt u-margin-bottom-small">
          Search Engine Optimization
        </h2>
        <p className="tech-sect-4__paragraph u-margin-bottom-medium">
          With every website project we build, we implement the foundational
          elements needed to drive visibility and boost search rankings.
        </p>
      </div>
    </div>
  );
};

export default SectionFour;
